<template>
    <div
        ref="tooltipContent"
        @mouseenter="handleMouseEnter"
        @mouseleave="hideTooltip"
        class="tooltip"
        :class="{
            'nowrap' : enabledIfTruncated,
            'is-disabled' : disabled
        }"
    >
        <slot />
    </div>
</template>

<script>
import tippy from 'tippy.js';

export default {
    name: 'Tooltip',
    inject: ['$isMobile'],
    props: {
        tooltipText: {
            type: String,
            default: ''
        },
        additionalText: {
            type: String,
            default: '' 
        },
        icon: {
            type: String,
            default: ''
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        enabledIfTruncated: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            shouldShowTooltip: false,
            tooltipInstance: null,
            isTruncated: false
        };
    },
    computed: {
        isDisabled() {
            return this.disabled || this.$isMobile() || (this.enabledIfTruncated && !this.isTruncated);
        },
        tooltipContent() {
            // Combine the icon, tooltip text, and additional text
            return this.icon
                ? `<div class="tooltip-content">
                        <span class="tooltip-icon">${this.icon}</span>
                        <span class="tooltip-text">${this.tooltipText}</span>
                        ${this.additionalText ? `<span class="tooltip-additional-text">${this.additionalText}</span>` : ''}
                   </div>`
                : `${this.tooltipText}${this.additionalText ? `<span class="tooltip-additional-text">${this.additionalText}</span>` : ''}`;
        }
    },
    watch: {
        tooltipText: {
            handler() {
                if (!this.tooltipInstance) return;
                this.tooltipInstance.setContent(this.tooltipContent);
            },
            immediate: true
        },
        isDisabled: {
            handler(val) {
                this.$nextTick(() => {
                    if (!this.tooltipInstance) return;
                    if (val) this.tooltipInstance.disable();
                    else this.tooltipInstance.enable();
                });
            },
            immediate: true
        },
        isTruncated(val) {
            if (val && this.shouldShowTooltip) this.showTooltip();
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initTooltip();

            // Attach mouseover and mouseleave listeners
            this.$refs.tooltipContent.addEventListener('mouseover', this.handleMouseOver);
            this.$refs.tooltipContent.addEventListener('mouseleave', this.handleMouseLeave);
        });
    },
    beforeDestroy() {
        this.destroyTooltip();

        this.$refs.tooltipContent.removeEventListener('mouseover', this.handleMouseOver);
        this.$refs.tooltipContent.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    methods: {
        async initTooltip() {
            this.tooltipInstance = tippy(this.$refs.tooltipContent, {
                content: this.tooltipContent,
                allowHTML: true,
                arrow: false,
                animation: null,
                interactive: true,
                placement: this.placement,
                appendTo: document.body,
                theme: 'tooltip',
                trigger: 'manual'
            });

            if (this.isDisabled) this.tooltipInstance.disable();
            await this.$nextTick();

            if (this.enabledIfTruncated) this.checkTruncation();
        },
        destroyTooltip() {
            if (this.tooltipInstance) this.tooltipInstance.destroy();
        },
        showTooltip() {
            this.shouldShowTooltip = true;
            this.tooltipInstance.show();
        },
        hideTooltip() {
            this.shouldShowTooltip = false;
            this.tooltipInstance.hide();
        },
        handleMouseEnter() {
            if (!this.isDisabled) {
                this.showTooltip();
            }
        },
        checkTruncation() {
            this.$nextTick(() => {
                const tooltipContent = this.$refs.tooltipContent;
                const slotContent = tooltipContent.firstElementChild;

                if (slotContent && tooltipContent) {
                    const contentRect = slotContent.getBoundingClientRect();
                    const containerRect = tooltipContent.getBoundingClientRect();
                    
                    this.isTruncated = contentRect.width > containerRect.width;
                }
            });
        },
        handleMouseOver(event) {
            // Check if the mouse is hovering over an element with the 'tooltip' class inside the slot
            const isInsideAnotherTooltip = event.target.closest('.tooltip') && event.target.closest('.tooltip') !== this.$refs.tooltipContent;
            if (isInsideAnotherTooltip) {
                this.hideTooltip(); // Hide the outer tooltip if inside another one
            } else if (!this.isDisabled) {
                this.showTooltip(); // Otherwise, show the outer tooltip
            }
        },
        handleMouseLeave(event) {
            const isStillInsideTooltip = this.$refs.tooltipContent.contains(event.relatedTarget);
            if (!isStillInsideTooltip) {
                this.hideTooltip(); // Hide tooltip when mouse leaves the entire component
            }
        }
    }
};
</script>

<style lang="scss">
[data-theme='tooltip'] {
    background-color: black;
    border: 1px solid rgb(91, 91, 91);
    color: white;
    text-align: center;
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tippy-content {
        max-width: 100%;
        word-wrap: break-word;
    }

    .tooltip-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 0;

        .tooltip-icon {
            font-size: 40px;
            margin-bottom: 4px;
        }

        .tooltip-text {
            font-size: 12px;
        }

        .tooltip-additional-text {
            font-size: 12px;
            opacity: 0.5; 
            margin-top: 4px;
        }
    }
}
</style>