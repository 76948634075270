import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

import Signup from './signup/_routes';

import { requireAuth, requireNoAuth } from '@/mixins/routerMixins';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: requireNoAuth,
  },
  ...Signup,
  {
    path: '/invite',
    name: 'invite',
    component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue'),
  },
  {
    path: '/template',
    name: 'use-case',
    component: () => import(/* webpackChunkName: "use-case" */ '../views/UseCase.vue'),
  },
  {
    path: '/passwordreset',
    name: 'passwordreset',
    component: () => import(/* webpackChunkName: "passwordreset" */ '../views/PasswordReset.vue'),
    beforeEnter: requireNoAuth,
  },
  {
    path: '/create-workspace',
    name: 'create-workspace',
    component: () => import(/* webpackChunkName: "create-workspace" */ '../views/CreateOrg.vue'),
  },
  {
    path: '/:org?',
    name: 'dashboard',
    props: true,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    beforeEnter: requireAuth,
    children: [
      {
        path: 'calls',
        redirect: { name: 'calls-home' }
      },
      {
        path: 'calls/:id',
        redirect: { name: 'calls-call' }
      },
      {
        path: 'meeting/:id',
        name: 'calls-call'
      },
      {
        path: 'meeting',
        redirect: { name: 'calls-home' }
      },
      {
        path: 'settings',
        name: 'settings-redirect',
        children: [
          {
            path: 'account',
            name: 'settings-redirect',
            redirect: { name: 'views-widgets' },
            redirect: {
              name: 'views-widgets',
              query: {
                settings: 'My account'
              }
            }
          },
          {
            path: 'organization',
            name: 'settings-redirect',
            redirect: {
              name: 'views-widgets',
              query: {
                settings: 'Settings'
              }
            }
          },
          {
            path: 'organization/users',
            name: 'settings-redirect',
            redirect: {
              name: 'views-widgets',
              query: {
                settings: 'Users'
              }
            }
          },
          {
            path: '*',
            name: 'settings-redirect',
            redirect: { name: 'views-widgets' },
            redirect: {
              name: 'views-widgets',
              query: {
                settings: 'My account'
              }
            }
          }
        ]
      },
      {
        path: '',
        component: () => import('../views/Views.vue'),
        children: [
          {
            path: 'inbox',
            component: () => import('../views/Inbox.vue'),
            name: 'inbox-home'
          },
          {
            path: 'my-dashboard',
            component: () => import( '../views/MyDashboard.vue'),
            name: 'views-my-dashboard',
          },
          {
            path: 'my-tasks',
            component: () => import( '../views/MyTasks.vue'),
            name: 'views-my-tasks',
          },
          {
            path: 'today',
            component: () => import( '../views/Today.vue'),
            name: 'views-today',
          },
          {
            path: 'trash',
            component: () => import('../views/Trash.vue'),
            name: 'views-trash'
          },
          {
            path: 'meetings',
            component: () => import('../views/Meetings.vue'),
            children: [
              {
                path: '',
                component: () => import('../views/MeetingsHome.vue'),
                name: 'calls-home'
              },
              {
                path: 'history',
                component: () => import('../views/MeetingsHistory.vue'),
                name: 'calls-history'
              },
              {
                path: 'people',
                component: () => import('../views/MeetingsPeople.vue'),
                name: 'calls-people'
              }
            ]
          },
          {
            path: ':id?/:taskViewId?',
            component: () => import('./views/Page.vue'),
            name: 'views-widgets',
          }
        ],
      }
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
